<template>
  <div fluid>
    <!-- <div class="pt-1 d-flex">
      <v-text-field
        style="max-width: 250px"
        dense
        filled
        class="pl-0 mr-2"
        placeholder="search"
        solo
        flat
        color="cyan"
        :loading="pageLoading"
      />
    </div> -->
    <v-card
      flat
      class="custom-setting-height custom-grey-border remove-border-radius"
    >
      <v-card-title class="headline grey lighten-4">
        <v-row style="overflow: auto">
          <v-col md="12">
            <v-simple-table
              class="simple-table custom-table-height"
              fixed-header
              style="height: calc(67vh - 240px)"
            >
              <thead>
                <tr>
                  <th class="simple-table-th">Sno.</th>
                  <th class="simple-table-th">Billing Type</th>
                  <th class="simple-table-th">Invoice No</th>
                  <th class="simple-table-th">Billing Date</th>
                  <th class="simple-table-th">Billing Day</th>
                  <th class="simple-table-th">Action</th>
                </tr>
              </thead>

              <tbody class="custom-border-bottom">
                <template v-if="scheduleArr?.length">
                  <tr
                    v-for="(row, index) in scheduleArr"
                    :key="index"
                    link
                    class="alternate-listing-row"
                  >
                    <td class="td">{{ index + 1 }}</td>
                    <td class="td text-capitalize">
                      <v-chip label small color="blue" text-color="white">
                        {{ row.billing_type }}</v-chip
                      >
                    </td>
                    <td class="td" width="18%">
                      <div class="d-flex">
                        <v-text-field
                          dense
                          filled
                          class="pl-0"
                          placeholder="Invoice No"
                          v-model="row.barcode"
                          solo
                          flat
                          color="cyan"
                          :loading="pageLoading"
                        />
                        <v-icon
                          :loading="pageLoading"
                          color="red"
                          :disabled="!row.barcode ? true : false"
                          v-on:click="removeRow(index)"
                        >
                          mdi-delete</v-icon
                        >
                      </div>
                    </td>
                    <td class="td">{{ formatDate(row.date) }}</td>
                    <td class="td">
                      <div class="d-flex">
                        {{ row.dayName }}
                      </div>
                    </td>
                    <td class="td">
                      <v-btn
                        :loading="pageLoading"
                        depressed
                        tile
                        color="cyan white--text"
                        v-on:click="saveInvoice(row)"
                      >
                        <v-icon size="15">mdi-pencil</v-icon> Update</v-btn
                      >
                    </td>
                  </tr>
                </template>
                <tr v-else>
                  <td :colspan="6" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no billing at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
  </div>
</template>

<script>
import { QUERY, PATCH } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import { BillingEventBus } from "@/core/lib/billing.invoice.lib.js";

export default {
  mixins: [CommonMixin],
  data() {
    return {
      pageLoading: false,
      scheduleArr: [],
    };
  },
  props: {
    detail: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  methods: {
    getBillings() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "billing-schedule-list",
          data: {
            related_id: this.detail?.id,
            type: this.type ? this.type : null,
          },
        })
        .then(({ data }) => {
          _this.scheduleArr = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    removeRow(index) {
      this.scheduleArr[index].barcode = null;
    },
    saveInvoice(data) {
      this.pageLoading = true;

      this.$store
        .dispatch(PATCH, {
          url: "billing-number/update/" + data.id,
          data: {
            invoice_number: data.barcode,
          },
        })
        .then(() => {
          this.getBillings();
        })
        .catch((error) => {
          this.logError(error);
          this.pageLoading = false;
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getBillings();

    BillingEventBus.$on("update:extend", () => {
      this.getBillings();
    });
  },

  components: {},
};
</script>
<style>
.td {
  font-size: 14px !important;
  font-weight: 500;
}
</style>
