<template>
  <v-row justify="center">
    <v-dialog
      origin="top center"
      v-model="dialogStatus"
      persistent
      content-class="warranty-dialog"
      :max-width="dialogWidth"
    >
      <v-card>
        <v-card-title
          class="headline dialog-custom-header white-background custom-border-bottom custom-header-blue-text"
        >
          Extend Billing
        </v-card-title>
        <v-card-text class="p-8 font-size-16">
          <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll"
            style="max-height: 90vh; position: relative"
          >
            <v-form
              ref="extendForm"
              v-model.trim="formValid"
              lazy-validation
              v-on:submit.stop.prevent="extendRentalBilling"
            >
              <v-container fluid>
                <v-row>
                  <v-col md="6" class="py-1">
                    <label class="font-weight-600 ml-1">Duration Type</label>
                    <v-select
                      :items="durationTypes"
                      dense
                      filled
                      v-model.trim="extendBilling.duration_type"
                      solo
                      flat
                      class="width-100"
                      label="Duration Type"
                      color="cyan"
                      item-text="text"
                      item-value="value"
                      item-color="cyan"
                      v-on:change="(e) => UpdateEndDate(e)"
                    ></v-select>
                  </v-col>

                  <v-col md="6" class="py-1">
                    <label class="font-weight-600 ml-1">Duration</label>
                    <v-text-field
                      id="month"
                      type="number"
                      v-model.trim="extendBilling.month"
                      dense
                      filled
                      label="Month"
                      solo
                      flat
                      color="cyan"
                      class="pr-2 width-100"
                      :min="1"
                      oninput="if(this.value < 0) this.value = 0;"
                      v-on:keyup="(e) => UpdateEndDate(e)"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="6" class="py-1">
                    <label class="font-weight-600 ml-1 required"
                      >Start Date</label
                    >

                    <date-picker
                      key="start-date"
                      id="start-date"
                      placeholder="Start Date"
                      :min-date="rentalDetail.cease_date"
                      v-model="extendBilling.start_date"
                      :rules="[
                        validateRules.required(
                          extendBilling.start_date,
                          'Start Date'
                        ),
                      ]"
                      v-on:change="(e) => UpdateEndDate(e)"
                      @change="updateFrequencyButtons"
                    />
                  </v-col>
                  <v-col md="6" class="py-1">
                    <label class="font-weight-600 ml-1 required"
                      >Cease Date</label
                    >
                    <date-picker
                      key="end-date"
                      id="end-date"
                      :min-date="extendBilling.start_date"
                      placeholder="Cease Date"
                      v-model="extendBilling.cease_date"
                      @change="updateFrequencyButtons"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="6" class="py-1">
                    <label class="font-weight-600 ml-1">Billing Freq.</label>
                    <div class="d-flex ml-1 flex-wrap">
                      <v-btn
                        class="mb-1"
                        depressed
                        :disabled="!frequencyEnabled.daily"
                        tile
                        :outlined="!(extendBilling.invoice_type == 'daily')"
                        @click="setInvoiceType('daily')"
                        color="light-green darken-3 white--text"
                      >
                        Daily
                      </v-btn>
                      <v-btn
                        class="ml-1"
                        depressed
                        :disabled="!frequencyEnabled.weekly"
                        tile
                        :outlined="!(extendBilling.invoice_type == 'weekly')"
                        @click="setInvoiceType('weekly')"
                        color="orange darken-4 white--text"
                      >
                        Weekly
                      </v-btn>
                      <v-btn
                        class="ml-1"
                        depressed
                        :disabled="!frequencyEnabled.monthly"
                        tile
                        :outlined="!(extendBilling.invoice_type == 'monthly')"
                        @click="setInvoiceType('monthly')"
                        color="red darken-3 white--text"
                      >
                        Monthly
                      </v-btn>
                      <v-btn
                        class="ml-1"
                        depressed
                        tile
                        :outlined="!(extendBilling.invoice_type == 'custom')"
                        color="blue darken-3 white--text"
                        @click="setInvoiceType('custom')"
                      >
                        Custom
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        depressed
                        tile
                        color="cyan white--text"
                        class="ml-1"
                        :disabled="billingSchedule?.length > 0 ? false : true"
                        v-on:click="scheduleConfirmDialog = true"
                        >Show Schedule</v-btn
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </perfect-scrollbar>
        </v-card-text>
        <v-divider class="my-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="warrant-manage-action">
            <v-btn
              :disabled="!formValid || pageLoading"
              :loading="pageLoading"
              v-on:click="extendRentalBilling"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              >Save
            </v-btn>
            <v-btn
              :loading="pageLoading"
              :disabled="pageLoading"
              v-on:click="$emit('close:dialog')"
              class="mx-2 custom-grey-border custom-bold-button"
              >Cancel
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
      <BillingSchedule
        :dialog="scheduleConfirmDialog"
        :key="`job-confirm-schedule-${uniqueCId}`"
        v-on:close="scheduleConfirmDialog = false"
        :detail="extendBilling?.invoice_type"
      />
    </v-dialog>
  </v-row>
</template>

<style scoped>
.v-dialog__content {
  align-items: start;
}
.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: 18px;
  padding: 0;
}
.v-text-field--filled > .v-input__control > .v-input__slot {
  min-height: 100%;
}
</style>

<script>
import {
  SET_BILLING_LIST,
  CLEAR_BILLING,
} from "@/core/services/store/common.module";
import DatePicker from "@/view/components/QDatePicker.vue";
import { mapGetters } from "vuex";
import BillingSchedule from "@/view/pages/rental/Billing-Schedule.vue";
import { PATCH } from "@/core/services/store/request.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import moment from "moment";
import { BillingEventBus } from "@/core/lib/billing.invoice.lib.js";
import { ErrorEventBus } from "@/core/lib/message.lib";

export default {
  name: "extend-rental",
  mixins: [ValidationMixin],
  props: {
    dialogStatus: {
      type: Boolean,
      default: false,
    },
    rentalDetail: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
  },
  components: {
    DatePicker,
    BillingSchedule,
  },
  watch: {},
  data() {
    return {
      uniqueCId: Number(new Date()),

      scheduleConfirmDialog: false,
      pageLoading: false,
      frequencyEnabled: {
        daily: false,
        weekly: false,
        monthly: false,
      },
      extendBilling: {
        duration_type: "month",
        month: 1,
        start_date: null,
        cease_date: null,
        invoice_type: null,
      },
      durationTypes: [
        {
          text: "Day(s)",
          value: "day",
        },
        {
          text: "Month(s)",
          value: "month",
        },
        {
          text: "Year(s)",
          value: "year",
        },
      ],
    };
  },
  methods: {
    updateFrequencyButtons() {
      if (!this.extendBilling.start_date || !this.extendBilling.cease_date) {
        this.resetFrequencyButtons();
        return;
      }

      const startDate = new Date(this.extendBilling.start_date);
      const endDate = new Date(this.extendBilling.cease_date);
      const diffDays = Math.floor(
        (endDate - startDate) / (1000 * 60 * 60 * 24)
      );

      // Enable buttons based on the difference
      this.frequencyEnabled.daily = diffDays >= 0 && diffDays < 7;
      this.frequencyEnabled.weekly = diffDays >= 7 && diffDays < 28;
      this.frequencyEnabled.monthly = diffDays >= 28;
      // Generate invoices if a frequency is already selected

      if (this.frequencyEnabled.daily) {
        this.extendBilling.invoice_type = "daily";
      } else if (this.frequencyEnabled.weekly) {
        this.extendBilling.invoice_type = "weekly";
      } else if (this.frequencyEnabled.monthly) {
        this.extendBilling.invoice_type = "monthly";
      }
      if (this.extendBilling.invoice_type) {
        this.generateInvoices();
      }
    },
    resetFrequencyButtons() {
      this.frequencyEnabled = {
        daily: false,
        weekly: false,
        monthly: false,
      };
    },
    setInvoiceType(type) {
      this.extendBilling.invoice_type = type;
      if (this.extendBilling.invoice_type == "custom") {
        let currentDate = new Date();
        const invoiceList = [];
        const date = currentDate.toISOString().split("T")[0];
        const dayName = currentDate.toLocaleDateString("en-US", {
          weekday: "long",
        });
        invoiceList.push({
          date: date,
          dayName: dayName,
        });
        this.$store.commit(SET_BILLING_LIST, invoiceList);
      } else {
        this.updateFrequencyButtons();
      }
    },
    generateInvoices() {
      if (!this.extendBilling.start_date || !this.extendBilling.cease_date)
        return;

      const startDate = new Date(this.extendBilling.start_date);
      const endDate = new Date(this.extendBilling.cease_date);
      const invoices = [];
      let currentDate = new Date(startDate);

      let incrementDays = 1; // Default for daily
      if (this.extendBilling.invoice_type === "weekly") incrementDays = 7;
      if (this.extendBilling.invoice_type === "monthly") {
        incrementDays = this.getDaysInMonth(
          currentDate.getFullYear(),
          currentDate.getMonth()
        );
      }

      while (currentDate <= endDate) {
        if (invoices.length >= 20) break;
        const date = currentDate.toISOString().split("T")[0];
        const dayName = currentDate.toLocaleDateString("en-US", {
          weekday: "long",
        });

        invoices.push({
          date: date,
          dayName: dayName,
        });

        if (this.extendBilling.invoice_type === "monthly") {
          currentDate.setMonth(currentDate.getMonth() + 1); // Increment by one month
          currentDate.setDate(1); // Reset to the first day of the month
        } else {
          currentDate.setDate(currentDate.getDate() + incrementDays);
        }
      }
      this.$store.commit(SET_BILLING_LIST, invoices);
    },
    getDaysInMonth(year, month) {
      return new Date(year, month + 1, 0).getDate();
    },
    UpdateEndDate(value = 0) {
      if (!value) return false;

      let startDate = "";
      let duration = 1;
      let duration_type = "month";

      duration = this.extendBilling.month;
      startDate = this.extendBilling.start_date;
      duration_type = this.extendBilling.duration_type;

      if (startDate) {
        let date = "";
        switch (duration_type) {
          case "day":
            date = moment(startDate, "YYYY-MM-DD").add(duration, "d");
            break;
          case "month":
            date = moment(startDate, "YYYY-MM-DD").add(duration, "M");
            break;
          case "year":
            date = moment(startDate, "YYYY-MM-DD").add(duration, "y");
            break;
        }

        if (date) {
          date = date.format("YYYY-MM-DD");
        }
        this.extendBilling.cease_date = date;
      }
      this.updateFrequencyButtons();
    },
    extendRentalBilling() {
      const _this = this;
      if (!_this.$refs.extendForm.validate()) {
        return false;
      }
      if (!this.extendBilling.invoice_type) {
        ErrorEventBus.$emit("update:error", "Please Select Billing Freq.");
      }
      this.pageLoading = true;

      this.$store
        .dispatch(PATCH, {
          url: "extend-billing/" + this.rentalDetail?.id,
          data: {
            duration_type: this.extendBilling.duration_type,
            month: this.extendBilling.month,
            start_date: this.extendBilling.start_date,
            cease_date: this.extendBilling.cease_date,
            invoice_type: this.extendBilling.invoice_type,
            billing_schedule: this.billingSchedule,
          },
        })
        .then(() => {
          this.$emit("update:rental", true);
          BillingEventBus.$emit("update:extend", true);
        })
        .catch((error) => {
          this.logError(error);
          this.pageLoading = false;
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
  },
  destroyed() {
    this.$store.commit(CLEAR_BILLING);
  },
  computed: {
    ...mapGetters(["billingSchedule"]),
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>
